// this file is generated using make index.js
import acr from "./acr.svg";
import add_button from "./add-button.svg";
import aks from "./aks.svg";
import alert from "./alert.svg";
import ansible from "./ansible.svg";
import apiconnect from "./apiconnect.svg";
import artifact_registry from "./artifact-registry.svg";
import aws from "./aws.svg";
import azure_acl from "./azure-acl.svg";
import azure_app_service from "./azure-app-service.svg";
import azure_devops from "./azure-devops.svg";
import azure_lb from "./azure-lb.svg";
import azure_log_analytics from "./azure-log-analytics.svg";
import azure_metrics from "./azure-metrics.svg";
import azure_service_bus from "./azure-service-bus.svg";
import azure_sql_server from "./azure-sql-server.svg";
import azure_sql from "./azure-sql.svg";
import azure_subscription from "./azure-subscription.svg";
import azure_vnet from "./azure-vnet.svg";
import azure from "./azure.svg";
import badge from "./badge.svg";
import bash from "./bash.svg";
import brain from "./brain.svg";
import ca from "./ca.svg";
import canary_checker from "./canary-checker.svg";
import cassandra from "./cassandra.svg";
import check from "./check.svg";
import cisco from "./cisco.svg";
import clock from "./clock.svg";
import cloud_build from "./cloud-build.svg";
import cloudformation from "./cloudformation.svg";
import cloudsql from "./cloudsql.svg";
import cloudwatch_alarm from "./cloudwatch-alarm.svg";
import cloudwatch_config from "./cloudwatch-config.svg";
import cloudwatch from "./cloudwatch.svg";
import cluster from "./cluster.svg";
import compute_engine from "./compute-engine.svg";
import connected from "./connected.svg";
import console from "./console.svg";
import consul from "./consul.svg";
import containerdPull from "./containerdPull.svg";
import containerdPush from "./containerdPush.svg";
import cpu from "./cpu.svg";
import database_plus from "./database-plus.svg";
import database2 from "./database2.svg";
import databases from "./databases.svg";
import datadog from "./datadog.svg";
import datapower_monitoring from "./datapower-monitoring.svg";
import datapower_routing from "./datapower-routing.svg";
import datapower_threat from "./datapower-threat.svg";
import datapower from "./datapower.svg";
import debian from "./debian.svg";
import decrease from "./decrease.svg";
import dex from "./dex.svg";
import disk from "./disk.svg";
import django from "./django.svg";
import dns from "./dns.svg";
import dockerPull from "./dockerPull.svg";
import dockerPush from "./dockerPush.svg";
import dotnet from "./dotnet.svg";
import dots_triple from "./dots-triple.svg";
import download from "./download.svg";
import dynatrace from "./dynatrace.svg";
import ec2 from "./ec2.svg";
import ecr from "./ecr.svg";
import eks from "./eks.svg";
import elastic from "./elastic.svg";
import elasticsearch from "./elasticsearch.svg";
import elb from "./elb.svg";
import envoy from "./envoy.svg";
import error from "./error.svg";
import etcd from "./etcd.svg";
import fire from "./fire.svg";
import flanksource_icon from "./flanksource-icon.svg";
import flanksource from "./flanksource.svg";
import fluentd from "./fluentd.svg";
import flux from "./flux.svg";
import folder from "./folder.svg";
import gcp_repos from "./gcp-repos.svg";
import gcp from "./gcp.svg";
import gcsBucket from "./gcsBucket.svg";
import gilab from "./gilab.svg";
import git from "./git.svg";
import github from "./github.svg";
import gke from "./gke.svg";
import google from "./google.svg";
import grafana from "./grafana.svg";
import graphql from "./graphql.svg";
import group from "./group.svg";
import grpc from "./grpc.svg";
import grpc2 from "./grpc2.svg";
import haproxy from "./haproxy.svg";
import harbor from "./harbor.svg";
import hazelcast from "./hazelcast.svg";
import helm from "./helm.svg";
import html from "./html.svg";
import http from "./http.svg";
import https from "./https.svg";
import ibm_cloud from "./ibm-cloud.svg";
import ibm_mq from "./ibm-mq.svg";
import ibm from "./ibm.svg";
import icmp from "./icmp.svg";
import increase from "./increase.svg";
import increase2 from "./increase2.svg";
import infinity from "./infinity.svg";
import ip from "./ip.svg";
import istio from "./istio.svg";
import jaegar from "./jaegar.svg";
import java from "./java.svg";
import jmeter from "./jmeter.svg";
import json from "./json.svg";
import junit from "./junit.svg";
import jwt from "./jwt.svg";
import k8s from "./k8s.svg";
import kubernetes from "./kubernetes.svg";
import ldap from "./ldap.svg";
import letsencrypt from "./letsencrypt.svg";
import lifebuoy from "./lifebuoy.svg";
import linux from "./linux.svg";
import lua from "./lua.svg";
import mem from "./mem.svg";
import memchache from "./memchache.svg";
import microsoft from "./microsoft.svg";
import mongo from "./mongo.svg";
import mongodb from "./mongodb.svg";
import mssql from "./mssql.svg";
import multiple from "./multiple.svg";
import mysql from "./mysql.svg";
import mysql_logo from "./mysql_logo.svg";
import namespace from "./namespace.svg";
import namespaces from "./namespaces.svg";
import newrelic from "./newrelic.svg";
import nginx from "./nginx.svg";
import nodejs from "./nodejs.svg";
import nodes from "./nodes.svg";
import npm from "./npm.svg";
import opa from "./opa.svg";
import openid from "./openid.svg";
import openshift from "./openshift.svg";
import oracle from "./oracle.svg";
import oracle_icon from "./oracle_icon.svg";
import pagerduty from "./pagerduty.svg";
import php from "./php.svg";
import pod from "./pod.svg";
import pods from "./pods.svg";
import postgres from "./postgres.svg";
import prometheus from "./prometheus.svg";
import python from "./python.svg";
import rabbitmq from "./rabbitmq.svg";
import rancher from "./rancher.svg";
import redhat from "./redhat.svg";
import redis from "./redis.svg";
import restic from "./restic.svg";
import restrict from "./restrict.svg";
import rocket from "./rocket.svg";
import ruby from "./ruby.svg";
import s3 from "./s3.svg";
import s3Bucket from "./s3Bucket.svg";
import seal from "./seal.svg";
import server from "./server.svg";
import servers from "./servers.svg";
import servers2 from "./servers2.svg";
import settings from "./settings.svg";
import shield from "./shield.svg";
import silence from "./silence.svg";
import slack from "./slack.svg";
import smb from "./smb.svg";
import source from "./source.svg";
import speak from "./speak.svg";
import spring from "./spring.svg";
import sql from "./sql.svg";
import sqlserver from "./sqlserver.svg";
import ssl from "./ssl.svg";
import ssl2 from "./ssl2.svg";
import stackdriver_monitoring from "./stackdriver-monitoring.svg";
import stackdriver_trace from "./stackdriver-trace.svg";
import stackdriver from "./stackdriver.svg";
import star from "./star.svg";
import structure from "./structure.svg";
import swagger from "./swagger.svg";
import tag from "./tag.svg";
import talk from "./talk.svg";
import target from "./target.svg";
import terraform from "./terraform.svg";
import timezone from "./timezone.svg";
import trafficlight from "./trafficlight.svg";
import tree from "./tree.svg";
import ubuntu from "./ubuntu.svg";
import vault from "./vault.svg";
import vsphere from "./vsphere.svg";
import world from "./world.svg";
import www from "./www.svg";
import xml from "./xml.svg";
export const Icons = {
"acr": acr,
"add-button": add_button,
"aks": aks,
"alert": alert,
"ansible": ansible,
"apiconnect": apiconnect,
"artifact-registry": artifact_registry,
"aws": aws,
"azure-acl": azure_acl,
"azure-app-service": azure_app_service,
"azure-devops": azure_devops,
"azure-lb": azure_lb,
"azure-log-analytics": azure_log_analytics,
"azure-metrics": azure_metrics,
"azure-service-bus": azure_service_bus,
"azure-sql-server": azure_sql_server,
"azure-sql": azure_sql,
"azure-subscription": azure_subscription,
"azure-vnet": azure_vnet,
"azure": azure,
"badge": badge,
"bash": bash,
"brain": brain,
"ca": ca,
"canary-checker": canary_checker,
"cassandra": cassandra,
"check": check,
"cisco": cisco,
"clock": clock,
"cloud-build": cloud_build,
"cloudformation": cloudformation,
"cloudsql": cloudsql,
"cloudwatch-alarm": cloudwatch_alarm,
"cloudwatch-config": cloudwatch_config,
"cloudwatch": cloudwatch,
"cluster": cluster,
"compute-engine": compute_engine,
"connected": connected,
"console": console,
"consul": consul,
"containerdPull": containerdPull,
"containerdPush": containerdPush,
"cpu": cpu,
"database-plus": database_plus,
"database2": database2,
"databases": databases,
"datadog": datadog,
"datapower-monitoring": datapower_monitoring,
"datapower-routing": datapower_routing,
"datapower-threat": datapower_threat,
"datapower": datapower,
"debian": debian,
"decrease": decrease,
"dex": dex,
"disk": disk,
"django": django,
"dns": dns,
"dockerPull": dockerPull,
"dockerPush": dockerPush,
"dotnet": dotnet,
"dots-triple": dots_triple,
"download": download,
"dynatrace": dynatrace,
"ec2": ec2,
"ecr": ecr,
"eks": eks,
"elastic": elastic,
"elasticsearch": elasticsearch,
"elb": elb,
"envoy": envoy,
"error": error,
"etcd": etcd,
"fire": fire,
"flanksource-icon": flanksource_icon,
"flanksource": flanksource,
"fluentd": fluentd,
"flux": flux,
"folder": folder,
"gcp-repos": gcp_repos,
"gcp": gcp,
"gcsBucket": gcsBucket,
"gilab": gilab,
"git": git,
"github": github,
"gke": gke,
"google": google,
"grafana": grafana,
"graphql": graphql,
"group": group,
"grpc": grpc,
"grpc2": grpc2,
"haproxy": haproxy,
"harbor": harbor,
"hazelcast": hazelcast,
"helm": helm,
"html": html,
"http": http,
"https": https,
"ibm-cloud": ibm_cloud,
"ibm-mq": ibm_mq,
"ibm": ibm,
"icmp": icmp,
"increase": increase,
"increase2": increase2,
"infinity": infinity,
"ip": ip,
"istio": istio,
"jaegar": jaegar,
"java": java,
"jmeter": jmeter,
"json": json,
"junit": junit,
"jwt": jwt,
"k8s": k8s,
"kubernetes": kubernetes,
"ldap": ldap,
"letsencrypt": letsencrypt,
"lifebuoy": lifebuoy,
"linux": linux,
"lua": lua,
"mem": mem,
"memchache": memchache,
"microsoft": microsoft,
"mongo": mongo,
"mongodb": mongodb,
"mssql": mssql,
"multiple": multiple,
"mysql": mysql,
"mysql_logo": mysql_logo,
"namespace": namespace,
"namespaces": namespaces,
"newrelic": newrelic,
"nginx": nginx,
"nodejs": nodejs,
"nodes": nodes,
"npm": npm,
"opa": opa,
"openid": openid,
"openshift": openshift,
"oracle": oracle,
"oracle_icon": oracle_icon,
"pagerduty": pagerduty,
"php": php,
"pod": pod,
"pods": pods,
"postgres": postgres,
"prometheus": prometheus,
"python": python,
"rabbitmq": rabbitmq,
"rancher": rancher,
"redhat": redhat,
"redis": redis,
"restic": restic,
"restrict": restrict,
"rocket": rocket,
"ruby": ruby,
"s3": s3,
"s3Bucket": s3Bucket,
"seal": seal,
"server": server,
"servers": servers,
"servers2": servers2,
"settings": settings,
"shield": shield,
"silence": silence,
"slack": slack,
"smb": smb,
"source": source,
"speak": speak,
"spring": spring,
"sql": sql,
"sqlserver": sqlserver,
"ssl": ssl,
"ssl2": ssl2,
"stackdriver-monitoring": stackdriver_monitoring,
"stackdriver-trace": stackdriver_trace,
"stackdriver": stackdriver,
"star": star,
"structure": structure,
"swagger": swagger,
"tag": tag,
"talk": talk,
"target": target,
"terraform": terraform,
"timezone": timezone,
"trafficlight": trafficlight,
"tree": tree,
"ubuntu": ubuntu,
"vault": vault,
"vsphere": vsphere,
"world": world,
"www": www,
"xml": xml,
}
